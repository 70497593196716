import EngynDataCenter from '../view-models/engyn-data-center'

// Add timestamp for cache busting
const updateRemoteEntryTimestamp = (url, timestamp) => {
  try {
    const parsedUrl = new URL(url)
    const searchParams = parsedUrl.searchParams

    searchParams.set('t', timestamp)

    return parsedUrl.toString()
  } catch {
    return url
  }
}

const composeRemoteEntryPath = (currentPath, targetVersion) => {
  // Regular expression to match the version part, if present
  const entryVersionRegex = /(remoteEntry)(-[^/]+)?(\.js)/

  // If a version is provided, replace or insert it
  if (targetVersion) {
    return currentPath.replace(entryVersionRegex, `$1-${targetVersion}$3`)
  }

  // If no version is provided, remove the version part
  return currentPath.replace(entryVersionRegex, `$1$3`)
}

const getMFRemoteEntry = () => ({
  name: 'get-micro-frontend-remote-entry',
  beforeRequest: args => {
    const remotes = args.options.remotes || []

    const webAppVersions = EngynDataCenter.getCurrentWebAppVersions() || {}

    remotes.forEach(remote => {
      // eslint-disable-next-line no-param-reassign
      remote.entry = updateRemoteEntryTimestamp(
        composeRemoteEntryPath(remote.entry, webAppVersions?.[remote.name]),
        window._engynGlobalTimestamp
      )
    })

    return args
  },
  errorLoadRemote: args => {
    const currentWebAppVersions =
      EngynDataCenter.getCurrentWebAppVersions() || {}
    const errorWebAppVersion = currentWebAppVersions[args.id]

    if (errorWebAppVersion) {
      delete currentWebAppVersions[args.id]
      EngynDataCenter.updateWebAppVersions(currentWebAppVersions)
      setTimeout(() => window.location.reload())
    }
  },
})

export default getMFRemoteEntry
